<template>
  <section class="page-content">

    <!-- Top Filters -->
    <CategoryFiltersComponent
      :sorting="sorting"
      @change-sorting="changeSorting"
      :select-options="selectOptions"
      :prods-per-page="pageInfo.page_size"
      @change-per-page="changePerPage"
      :products-count="totalProducts"
    />

    <!-- Products -->
    <b-row v-if="isLoading" class="pro-loader">
        <div class="reverse-spinner"></div>
    </b-row>

    <b-row v-else-if="totalProducts === 0">
      <b-col>
        <p class="text-center">{{ $t("no_products") }}</p>
      </b-col>
    </b-row>

    <b-row
      class="category-products-grid mx-0"
      id="category-products-grid"
      v-else
    >
      <b-col
        v-for="(product, index) in products"
        v-bind:key="index"
        class="category-products-item p-0"
      >
        <ProductCard :product="product" />
      </b-col>
    </b-row>
      
    <!-- Bottom Section (pagination/filter) -->
    <div
      v-if="totalProducts > 0"
      class="pb-30 pagination-header category-right-top-list-header mt-0"
    >
      <div class="category-list-left">
        {{ totalProducts }} {{ $t("result") }} | &nbsp; {{ $t("sort_by") }}
        <span class="count-icon">
          <select
            id="collection"
            class="filter-list bold"
            :value="sorting"
            @change="changeSorting"
          >
            <option
              v-for="option in selectOptions"
              v-bind:key="option.value"
              v-bind:value="option.value"
            >
              {{ option.text }}
            </option>
          </select>
        </span>
      </div>
      <div class="category-list-right pagination-wrap">
        <b-pagination
          :value="pageInfo.current_page"
          @change="changePage($event)"
          :per-page="pageInfo.page_size"
          :total-rows="totalProducts"
          :limit="3"
          :prev-class="{ 'd-none': pageInfo.current_page == 1 }"
          :next-class="{
            'd-none': pageInfo.current_page == Math.ceil(totalProducts / pageInfo.page_size),
          }"
        >
          <template #page="{ page }">
            <b-link @click="goToPage(page, $event)" ref="paginationLink">
              {{ page }}
            </b-link>
          </template>
        </b-pagination>
      </div>
    </div>

  </section>
</template>

<script>
import config from "@config";
import ProductCard from "@/esf_utrecht_bankxl/core/components/products/category-product/ProductCard.vue";
import CategoryFiltersComponent from "@/esf_utrecht_bankxl/core/components/products/category-product/CategoryFiltersComponent.vue";
export default {
  name: "BrandPageContent",

  data() {
    return {
      isLoading: true,
    }
  },

  components: {
    ProductCard,
    CategoryFiltersComponent,
  },

  computed: {
    products() {
      return this.$store.getters['product/getBrandProducts'] || [];
    },

    pageInfo() {
      return this.$store.getters['product/getBrandPageInfo'] || {};
    },

    totalProducts() {
      return this.$store.getters['product/getBrandProductsTotalCount'] || 0;
    },

    sorting() {
      return this.$store.getters["product/getSorting"];
    },

    selectOptions() {
      const sortOpt = [];
      Object.keys(config.sortingOptions).forEach((element) => {
        const option = {
          value: element,
          text: this.$t(`sortingOptions.${element}`),
        };
        sortOpt.push(option);
      });
      return sortOpt;
    },
  },

  mounted() {
    this.fetchBrand();
  },

  methods: {

    // Change the pagination
    changePage(pageNo) {
      localStorage.setItem("v-remember-scroll-position", window.scrollY);
      localStorage.setItem("v-remember-scroll-page", window.location.pathname);
      
      this.$store.dispatch("product/updateCurrentPage", pageNo);
    },

    goToPage(page) {
      localStorage.setItem("v-remember-scroll-position", window.scrollY);
      localStorage.setItem("v-remember-scroll-page", window.location.pathname);
      const query = { ...this.$route.query };

      if (page != 1) query["page"] = page;
      else delete query["page"];
    },

    // Fetch the specific brand details and its products
    async fetchBrand() {
      const key = this.$route.params.code;
      const retval = await this.$store.dispatch("product/getBrandDetail", { urlKey: key });

      // Get products
      if (retval) {
        await this.$store.dispatch("product/getProductByBrand");
        this.isLoading = false;
      } else {
        console.error("Error fetching brand detail");
      }
    },

    // Get the products as per sorting
    changeSorting(event) {
      const sortVal = event.target.value;
      this.$store.commit("product/setSorting", sortVal);
    },

    // Change the product size on page
    changePerPage(event) {
      const perPage = +event.target.value;
      this.$store.commit("product/setProductsPerPage", perPage);
    },
  }
};
</script>

<style lang="scss" scoped>
.pro-loader {
  text-align: center;
  padding: 100px 0px 120px 0px;
  .reverse-spinner {
    position: relative;
    height: 100px;
    width: 100px;
    margin: 0 auto;
    border: 4px solid transparent;
    border-top-color: $primary-gray;
    border-left-color: $primary-gray;
    border-radius: 50%;
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
  }
  @media screen and (max-width: 560px) {
    padding: 50px 0px;
    .reverse-spinner {
      height: 80px;
      width: 80px;
    }
  }

  .reverse-spinner::before {
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    content: "";
    border: 4px solid transparent;
    border-top-color: $primary-gray;
    border-left-color: $primary-gray;
    opacity: 0.7;
    border-radius: 50%;
    -webkit-animation: spinBack 1s linear infinite;
    animation: spinBack 1s linear infinite;
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinBack {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(-720deg);
    transform: rotate(-720deg);
  }
}
</style>

