<template>
  <div class="brandPage top-padding">
    <b-container>

      <!-- Breadcrumb -->
      <Breadcrumbs />

      <div class="row product-item-row mt-2">

        <!-- Side Filters -->
         <div class="hiddenElement" ref="filterButton"></div>
          <div class="col-12 tab-mob-show" :class="stickyClass">
            <div
              class="pb-2 pt-2 filter-result text-center text-white bg-primary filter-btn-wrap"
            >
              <span class="filter-icon"
                ><font-awesome-icon icon="filter"
              /></span>
              <span class="filter-txt pl-2" @click="openSidebar"
                >{{ $t("search_help") }} {{ totalProducts }}
                {{ $t("result") }}</span
              >
            </div>
          </div>
        <ProductFilter class="col-lg-3" :isActive="isActive"  @close-sidebar="closeSidebar" />
        
        <!-- Products and Pagination -->
        <BrandPageContent class="col-lg-9" />
      </div>
    </b-container>
  </div>
</template>

<script>
import Breadcrumbs from "@/esf_utrecht_bankxl/core/components/core/Breadcrumbs.vue";
import ProductFilter from "@/esf_utrecht_bankxl/core/components/brands/ProductFilter.vue";
import BrandPageContent from "@/esf_utrecht_bankxl/core/components/brands/BrandPageContent.vue";

export default {
  name: "Brand",
  
  components: {
    Breadcrumbs,
    ProductFilter,
    BrandPageContent,
  },

  data() {
    return {
      isActive: false,
      stickyClass: "nonStickyButton",
    };
  },

  provide() {
    return {
      openSidebar: this.openSidebar,
      closeSidebar: this.closeSidebar,
    };
  },

  mounted() {
    window.addEventListener("scroll", this.handleScroll);

    // get breadcrumb data
    const bcrumb = {
      current: this.currentbrand.title,
      routes: [
        {
          name: this.$t("brands"),
          route_link: "/merken",
        },
      ],
    };
    this.$store.commit("breadcrumbs/set", bcrumb);
  },

  computed: {
    currentbrand() {
      return this.$store.getters["product/getBrandSliderByCode"](
        this.$route.params.code
      );
    },
    totalProducts() {
      return this.$store.getters['product/getBrandProductsTotalCount'] || 0;
    },
  },

  methods: {
    openSidebar() {
      this.isActive = true;
    },
    closeSidebar() {
      this.isActive = false;
    },
    handleScroll() {
      var top = this.$refs.filterButton.getBoundingClientRect().top;
      const currentScrollPosition =
        window.scrollY || document.documentElement.scrollTop;

      if (
        Math.abs(currentScrollPosition - this.lastScrollPosition) < 60 &&
        currentScrollPosition < this.lastScrollPosition
      ) {
        this.stickyClass = "top60";
      } else {
        this.stickyClass = "";
      }
      this.lastScrollPosition = currentScrollPosition;
      if (top <= 0) {
        this.stickyClass += " stickyButton";
      } else {
        this.stickyClass += " nonStickyButton";
      }
    },
  },

  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
